import { Component, Vue } from 'vue-property-decorator'


//components
import PrivateContent from '@/components/privateContent/PrivateContent.vue'
import FieldSearch from '@/components/fieldSearch/FieldSearch.vue'
import Etapa1 from '@/views/public/ouvidoria/components/etapa1/Etapas1.vue'
import Etapa2 from '@/views/public/ouvidoria/components/etapa2/Etapa2.vue'
import Etapa3 from '@/views/public/ouvidoria/components/etapa3/Etapa3.vue'
import Etapa4 from '@/views/public/ouvidoria/components/etapa4/Etapa4.vue'
import Etapa5 from '@/views/public/ouvidoria/components/etapa5/Etapa5.vue'

//wrappers
import {
	WForm,
	Box,
	WButton,
	WSubtitle
} from '@/components/wrappers';

@Component({
	components: {
		PrivateContent,
		FieldSearch,
		WForm,
		Box,
		WButton,
		WSubtitle,
		Etapa1,
		Etapa2,
		Etapa3,
		Etapa4,
		Etapa5
	}
})

export default class Etapas extends Vue {

	showSteps = true

	mounted() {
		window.scrollTo(0, 0)
		// this.step = 0;
		this.isIdentify = true;
	}

	get step(){
		return this.$store.getters['ouvidoria/getStep']
	}
	set step(newValue: any){
		this.$store.commit('ouvidoria/setStep', newValue);
	}
	get isIdentify(){
		return this.$store.getters['ouvidoria/getIsIdentify']
	}
	set isIdentify(newValue: any){
		this.$store.commit('ouvidoria/setIsIdentify', newValue);
	}
	get hasNavigation(){
		return this.$store.getters['ouvidoria/getHasNavigation']
	}
	set hasNavigation(newValue: any){
		this.$store.commit('ouvidoria/setHasNavigation', newValue);
	}
	get type(){
		return this.$store.getters['ouvidoria/getType']
	}
	set type(newValue: any){
		this.$store.commit('ouvidoria/setType', newValue);
	}
	get typeTitle(){
		return this.$store.getters['ouvidoria/getTypeTitle']
	}

	clickVoltar(previous: any){
		previous.action()
	}

	clickNext(next: any){
		next.action()
	}

	//  ===== config steps =====
	showFormLogin = false

	// activeStep = this.step

	showSocial = false
	isAnimated = true
	isRounded = true
	isStepsClickable = false
	
	customNavigation = true
	isProfileSuccess = false

	prevIcon = 'chevron-left'
	nextIcon = 'chevron-right'
	labelPosition = 'bottom'
	mobileMode = 'minimalist'

	// ======= backgrounds

	arrBkgs = {
		denuncia: `rgb(225, 38, 0)`,
		reclamacao: `rgb(225, 172, 45)`,
		solicitacao: `rgb(0, 135, 217)`,
		sugestao: `rgb(153, 98, 168)`,
		elogio: `rgb(93, 179, 0)`
	}

	// ======= textos

	arrTexts = {
		denuncia: `DENÚNCIA: Comunicação de prática de ato ilícito cuja solução dependa da atuação de órgão de controle interno ou externo.
		Nese esta canal para comunicar a ocorrência de um ato ilícito, uma irregularidade praticada por agentes públicos. Também pode ser usada para denunciar uma violação aos direitos humanos.
		Em alguns casos, a sua manifestação não será uma denúncia e sim uma solicitação. Por exemplo, se faltam remédios em um hospital público, você poderá fazer uma solicitação para que o órgão tome uma providência. Então, não se trata de uma denúncia.`,
		reclamacao: `RECLAMAÇÃO: demonstração de insatisfação relativa a serviço público; e
		Use este canal para demonstrar a sua insatisfação com um serviço público, você pode fazer críticas, relatar ineficiência.	
		Também se aplica aos casos de omissão. Por exemplo, você procurou um atendimento ou serviço, e não teve resposta.`,
		solicitacao: `SOLICITAÇÃO: requerimento de adoção de providência por parte da Administração;
		Use este canal para solicitar um atendimento ou a prestação de um serviço. Pode ser algo material, como receber um medicamento, ou a ação do órgão em uma situação específica.	
		Por exemplo, se alimentos fora da validade estiverem à venda, você pode solicitar que um órgão público faça uma fiscalização.`,
		sugestao: `SUGESTÃO: proposição de ideia ou formulação de proposta de aprimoramento de políticas e serviços prestados pela Administração Pública Municipal;
		Utilize este canal para enviar uma ideia, ou proposta de melhoria dos serviços públicos.`,
		elogio: `ELOGIO: Demonstração ou reconhecimento ou satisfação sobre o serviço oferecido ou atendimento recebido;
		Utilize esta canal se você foi bem atendimento e está satisfeito com o atendimento, e/ou com o serviço que foi prestado.`
	}
	
}