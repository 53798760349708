import { Component, Vue } from 'vue-property-decorator'

//molds
import { Form, Input } from '@/components/wrappers/molds/Form';

//components
import PrivateContent from '@/components/privateContent/PrivateContent.vue'
import FieldSearch from '@/components/fieldSearch/FieldSearch.vue'

//wrappers
import {
	WForm,
	Box,
	WButton,
	WSubtitle
} from '@/components/wrappers';

@Component({
	components: {
		PrivateContent,
		FieldSearch,
		WForm,
		Box,
		WButton,
		WSubtitle
	}
})

export default class Etapa4 extends Vue {

	dropFiles: any = []

	deleteDropFile(index: any) {
    this.dropFiles.splice(index, 1)
  }

	clickEnviarAnexo(){
		const res = this.$store.dispatch('ouvidoria/sendAnexos', this.dropFiles)
		res.then( (result) => {
			if( result instanceof Error ){
				console.log(result);
			}
			else{
				this.step = (this.step +1)
			}
		})
	}

	clickBtnFinalNoFiles(){
		this.step = this.step + 1
	}


	get isLoading(){
		return this.$store.getters['ouvidoria/getIsLoading'];
	}
	get step(){
		return this.$store.getters['ouvidoria/getStep']
	}
	set step(newValue: any){
		this.$store.commit('ouvidoria/setStep', newValue);
	}
	get typeTitle(){
		return this.$store.getters['ouvidoria/getTypeTitle']
	}

	get newProcess(){
		return this.$store.getters['ouvidoria/getNewProcess']
	}

	assuntosDenuncia = [
		{
			label: "Desativado",
			value: "0"
		},
		{
			label: "Cadastrado",
			value: "1"
		},
		{
			label: "Pendente de Autorização",
			value: "2"
		},
		{
			label: "Ativo",
			value: "3"
		},
		{
			label: "Afastado",
			value: "4"
		},
		{
			label: "Desativado (temporariamente)",
			value: "5"
		},
	]

}