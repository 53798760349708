import { Component, Vue } from 'vue-property-decorator'

//molds
import { Form, Input } from '@/components/wrappers/molds/Form';

//components
import PrivateContent from '@/components/privateContent/PrivateContent.vue'
import FieldSearch from '@/components/fieldSearch/FieldSearch.vue'

//wrappers
import {
	WForm,
	Box,
	WButton,
	WSubtitle,
	Loader
} from '@/components/wrappers';

@Component({
	components: {
		PrivateContent,
		FieldSearch,
		WForm,
		Box,
		WButton,
		WSubtitle,
		Loader
	}
})

export default class Etapa3 extends Vue {

	form: any = {
		email: '',
		menssagem: ''
	}

	assuntos = []

	mounted() {
		this.assuntos = this.todosAssuntos[this.type]
	}
	
	clickBtnProsseguir(){
		console.log(this.form);
		const data: any = {};
		data.template = this.type;
		data.subject = this.form.assunto != undefined? this.form.assunto: 'Sem Assunto';
		// data.details = this.form.assunto != undefined? this.form.assunto: 'Sem Assunto';
		data.details = this.form.menssagem;
		data.moreDetails = {
			email: this.form.email,
      message: this.form.menssagem
		};
		const res = this.$store.dispatch('ouvidoria/sendFormNewProcess', data)
		res.then( (result) => {
			if( result instanceof Error ){
				console.log(result);
			}
			else{
				this.step = (this.step +1)
			}
		})
	}

	returno = {
    "internalExternal": "E",
    "subject": "processo",
    "status": "3",
    "type": "reclamacao",
    "idGroupDestiny": "2",
    "idGroupLocal": "2",
    "details": "Assunto da reclamação",
    "idUserOrigin": "1",
    "num": 8,
    "idData": "8",
    "year": "2021",
    "dateTimeOpened": "2021-07-10 11:48:45",
    "idUserOpening": "1",
    "idProcess": "8",
    "history": {
        "operation": 3,
        "idGroup": "2",
        "idProcess": "8",
        "operationName": "Não Recebido",
        "datetime": "2021-07-10 11:48:45",
        "idUser": "1",
        "idHistory": "10"
    }
} 

	get step(){
		return this.$store.getters['ouvidoria/getStep']
	}
	set step(newValue: any){
		this.$store.commit('ouvidoria/setStep', newValue);
	}
	get type(){
		return this.$store.getters['ouvidoria/getType']
	}
	get typeTitle(){
		return this.$store.getters['ouvidoria/getTypeTitle']
	}
	get isLoading(){
		return this.$store.getters['ouvidoria/getIsLoading'];
	}

	todosAssuntos: any = {
		denuncia: [
			{
				label: "Contra o Município",
				value: "0"
			},
			{
				label: "Contra prestadores de serviços",
				value: "1"
			},
			{
				label: "Contra servidor",
				value: "2"
			},
			{
				label: "Desvio de material",
				value: "3"
			},
			{
				label: "Irregularidade em obras, pavimentação, reforma",
				value: "4"
			},
			{
				label: "Mau uso do bem público",
				value: "5"
			},
			{
				label: "Outras denúncias",
				value: "6"
			},
			{
				label: "Relacionadas ao Coronavírus (Covid-19)",
				value: "7"
			},
		],
		reclamacao: [
			{
				label: "Atendimento ao Cidadão",
				value: "0"
			},
			{
				label: "Contra prestadores de serviços",
				value: "1"
			},
			{
				label: "Contra servidor",
				value: "2"
			},
			{
				label: "Mau uso do bem público",
				value: "3"
			},
			{
				label: "Outras Reclamações",
				value: "4"
			},
			{
				label: "Relacionadas ao Coronavírus (Covid-19)",
				value: "5"
			},
		],
		solicitacao: [
			{
				label: "Iluminação Pública",
				value: "0"
			},
			{
				label: "Outros Serviços",
				value: "1"
			},
			{
				label: "Recolocação de Calçamento",
				value: "2"
			},
			{
				label: "Retirada de Entulho",
				value: "3"
			},
			{
				label: "Retirada de Lixo",
				value: "4"
			},
			{
				label: "Relacionadas ao Coronavírus (Covid-19)",
				value: "5"
			},
			{
				label: "Solicitação",
				value: "6"
			},
		],
		sugestao: [
			{
				label: "Sugestão",
				value: "0"
			},
			{
				label: "Relacionadas ao Coronavírus (Covid-19)",
				value: "1"
			}
		],
		elogio: [
			{
				label: "Elogio",
				value: "0"
			},
			{
				label: "Relacionadas ao Coronavírus (Covid-19)",
				value: "1"
			}
		]
	}

	



	tiposManifestacoes = [
		{
			value: 'denuncia',
			title: 'Denúncia'
		},
		{
			value: 'reclamacao',
			title: 'Reclamação'
		},
		{
			value: 'solicitacao',
			title: 'Solicitação'
		},
		{
			value: 'sugestao',
			title: 'Sugestão'
		},
		{
			value: 'elogio',
			title: 'Elogio'
		}
	]

}