import { Component, Vue } from 'vue-property-decorator'


//components
import PrivateContent from '@/components/privateContent/PrivateContent.vue'
import FieldSearch from '@/components/fieldSearch/FieldSearch.vue'

//wrappers
import {
	WForm,
	Box,
	WButton,
	WSubtitle
} from '@/components/wrappers';

@Component({
	components: {
		PrivateContent,
		FieldSearch,
		WForm,
		Box,
		WButton,
		WSubtitle
	}
})

export default class Etapas1 extends Vue {

	mounted() {
		this.step = 0	
	}

	get step(){
		return this.$store.getters['ouvidoria/getStep']
	}
	set step(newValue: any){
		this.$store.commit('ouvidoria/setStep', newValue);
	}
	get isIdentify(){
		return this.$store.getters['ouvidoria/getIsIdentify']
	}
	set isIdentify(newValue: any){
		this.$store.commit('ouvidoria/setIsIdentify', newValue);
	}
	get type(){
		return this.$store.getters['ouvidoria/getType']
	}
	set type(newValue: any){
		this.$store.commit('ouvidoria/setType', newValue);
	}

	clickProsseguirNoRestrict(){
		this.isIdentify = true;
		this.step = 1;
	}

	clickProsseguirRestrict(){
		this.isIdentify = true;
		this.step = 1;
	}



	clickProsseguirNoIdentify(){
		const data = {
			name: 'Usuário anonimo',
			document: '86101192000148',
			birthDate: '2021-07-08'
		}
		const res = this.$store.dispatch('ouvidoria/auth', data)
		res.then( (result) => {
			if( result instanceof Error ){
				console.log(result);
			}
			else{
				this.isIdentify = false;
				this.step = 2;
			}
		})
	}

	txtIdentfiCondition = `<div class=" has-text-centered"><span class="is-size-4">
		Quero me identificar com restrição</span></div>
		<strong>Aviso</strong><br>
		Por força da Lei nº 12.527/11 (Lei de Acesso à Informação), os 
		órgãos e entidades públicas devem proteger suas informações 
		pessoais, restringindo o acesso a quaisquer dados relativos 
		à intimidade, vida privada, honra e imagem, exceto nos casos
		em que é obrigada a divulgá-las por previsão em lei ou ordem 
		judicial.`;

	showIdentfiCondition = false;

	txtNoIdentifi = `<div class=" has-text-centered"><span class="is-size-4">
		Não quero me identificar</span></div>
		<strong>Aviso</strong><br>
		As manifestações registradas de maneira anônima são 
		consideradas “Comunicações” e não é possível o seu 
		acompanhamento. Caso deseje acompanhar o andamento 
		da sua manifestação e receber uma resposta do órgão 
		ou entidade, por favor identifique-se.`;

	showNoIdentifi = false;

}