import { Component, Vue } from 'vue-property-decorator'

//molds
import { Form, Input } from '@/components/wrappers/molds/Form';

//components
import PrivateContent from '@/components/privateContent/PrivateContent.vue'
import FieldSearch from '@/components/fieldSearch/FieldSearch.vue'

//wrappers
import {
	WForm,
	Box,
	WButton,
	WSubtitle
} from '@/components/wrappers';

@Component({
	components: {
		PrivateContent,
		FieldSearch,
		WForm,
		Box,
		WButton,
		WSubtitle
	}
})

export default class Etapa5 extends Vue {

	get newProcess(){
		return this.$store.getters['ouvidoria/getNewProcess']
	}
	get isIdentify(){
		return this.$store.getters['ouvidoria/getIsIdentify']
	}
	

}