import { Component, Vue } from 'vue-property-decorator'

//molds
import { Form, Input } from '@/components/wrappers/molds/Form';

//components
import PrivateContent from '@/components/privateContent/PrivateContent.vue'
import FieldSearch from '@/components/fieldSearch/FieldSearch.vue'

//wrappers
import {
	WForm,
	Box,
	WButton,
	WSubtitle,
	Loader
} from '@/components/wrappers';

@Component({
	components: {
		PrivateContent,
		FieldSearch,
		WForm,
		Box,
		WButton,
		WSubtitle,
		Loader
	}
})

export default class Etapa2 extends Vue {

	dispatchForm(data: any){
		console.log('dispatchForm', data);
		const res = this.$store.dispatch('ouvidoria/auth', data)
		res.then( (result) => {
			if( result instanceof Error ){
				console.log(result);
			}
			else{
				this.step = (this.step +1)
			}
		})
	}

	get step(){
		return this.$store.getters['ouvidoria/getStep']
	}
	set step(newValue: any){
		this.$store.commit('ouvidoria/setStep', newValue);
	}

	get isLoading(){
		return this.$store.getters['ouvidoria/getIsLoading'];
	}

	get formAcesso(): Form {
		return {
			flex: false,
			indent: true,
			grid: [
				[
					{
						fieldName: "name",
						label: "Nome",
						type: "text",
						maxlength: 100,
						placeholder: "nome",
						required: true,
						value: ""
					}
				],
				[
					{
						fieldName: "document",
						label: "Documento",
						type: "document",
						maxlength: 50,
						placeholder: "Documento",
						required: true,
						value:""
					}
				],
				[
					{
						fieldName: "birthDate",
						label: "Data de Nascimento",
						type: "date",
						maxlength: 10,
						required: true,
						value: ""
					}
				],
			]
		}
	}


}